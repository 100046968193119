<template>
  <div class="container my-4">

    <PrimePreloader v-if="loading"/>

    <div v-else>

      <div v-if="signatories.length">
        <h2 class="text-center mb-4">Список подписавших</h2>

        <DataTable :value="signatories" showGridlines stripedRows>
          <Column field="commonName" header="ФИО"></Column>
          <Column field="time" header="Дата подписания"></Column>
        </DataTable>
      </div>

      <div v-else class="d-flex align-items-center justify-content-center vh-100">
        <div class="text-center">
          <div class="mb-3">
            <i class="pi pi-file" style="font-size: 2.5rem;"/>
          </div>
          <h2>
            Файл не подписали
          </h2>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import httpClient from "@/services/http.service";

export default {
  name: "QrVerify",
  data() {
    return {
      loading: true,
      signatories: []
    }
  },
  async mounted() {
    try {
      const key = Object.keys(this.$route.query)[0]
      const value = Object.values(this.$route.query)[0]
      if (key && value) {
        const res = await httpClient.get(`signature/signature/signed-info?category=${key}&file_id=${value}`)
        this.signatories = res.data
      }
    } catch (e) {
      this.$error({
        title: e
      })
    }
    this.loading = false
  }
}
</script>
<style scoped>

</style>
